import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { ExcludeDates } from './components/exclude-dates/ExcludeDates';
import { Login } from './components/login/Login';
import { Reservations } from './components/reservations/Reservations';

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'rezerwacje',
        element: <Reservations />,
      },
      {
        path: 'wykluczenia-dat',
        element: <ExcludeDates />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
