import { useGetExcludeDatesQuery } from '@/api/date-exclusions';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { AddEditExcludeDate } from './AddEditExcludeDate';
import { DataTable } from './DataTable';
import { columns } from './columns';

export const ExcludeDates = (): ReactElement => {
  const { data, isLoading } = useGetExcludeDatesQuery();
  const dateExclusions = data?.dateExclusions?.length;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-10 mt-12 mb-8">
        <h1 className="text-2xl font-semibold leading-loose">
          Wykluczenia dat
        </h1>
        {!!dateExclusions && <AddEditExcludeDate light />}
      </div>

      {!!dateExclusions ? (
        <DataTable columns={columns} data={data.dateExclusions} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego wykluczenia daty"
          element={<AddEditExcludeDate />}
        />
      )}
    </div>
  );
};
