import calendar from '@/assets/calendar.svg';
import home from '@/assets/home.svg';
import { MenuListType } from '@/types/menuListType';

export const menuList: MenuListType[] = [
  {
    name: 'Rezerwacje',
    href: '/rezerwacje',
    icon: calendar,
  },
  {
    name: 'Wykluczenia dat',
    href: '/wykluczenia-dat',
    icon: calendar,
  },
];
