import { reservationsApiEndpoints } from '@/api/reservations';
import { Reservation } from '@/types/reservation';
import { createSlice } from '@reduxjs/toolkit';

export type ReservationsStore = {
  selectedReservation?: Reservation;
  reservations: Reservation[];
};

const initialState: ReservationsStore = {
  selectedReservation: undefined,
  reservations: [],
};

const reservationSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(
        reservationsApiEndpoints.getReservations.matchFulfilled,
        (state, { payload }) => {
          state.reservations = payload.reservations;
        },
      )
      .addMatcher(
        reservationsApiEndpoints.getReservationById.matchFulfilled,
        (state, { payload }) => {
          state.selectedReservation = payload.reservation;
        },
      );
  },
  name: 'reservations',
  initialState,
  reducers: {},
});

export const reservationsReducer = reservationSlice.reducer;
