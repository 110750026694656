import logo from '@/assets/logo.webp';
import { ReactElement } from 'react';
import { MenuButtons } from './MenuButtons';
import { MenuUser } from './MenuUser';

export const Menu = (): ReactElement => {
  return (
    <nav className="flex justify-between items-center w-full px-10 bg-white border-b border-slate-200">
      <div className="flex gap-x-6">
        <a href="/rezerwacje">
          <img src={logo} className="w-24.5" />
        </a>
        <MenuButtons />
      </div>
      <MenuUser />
    </nav>
  );
};
