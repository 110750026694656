import { ExcludeDate } from '@/types/excludeDate';
import { api } from './api';

const excludeDatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExcludeDates: builder.query<{ dateExclusions: ExcludeDate[] }, void>({
      providesTags: ['ExcludeDate'],
      query: () => `/date-exclusions`,
    }),
    createExcludeDate: builder.mutation<
      { excludeDate: ExcludeDate },
      Partial<ExcludeDate>
    >({
      invalidatesTags: ['ExcludeDate'],
      query: (body) => ({
        url: `/date-exclusions`,
        method: 'POST',
        body,
      }),
    }),
    getExcludeDateById: builder.query<
      { excludeDate: ExcludeDate },
      { excludeDateId?: ExcludeDate['id'] }
    >({
      providesTags: ['ExcludeDate'],
      query: ({ excludeDateId }) => `/date-exclusions/${excludeDateId}`,
    }),
    updateExcludeDate: builder.mutation<
      { excludeDate: ExcludeDate },
      { excludeDateId: ExcludeDate['id']; body: Partial<ExcludeDate> }
    >({
      invalidatesTags: ['ExcludeDate'],
      query: ({ excludeDateId, body }) => ({
        url: `/date-exclusions/${excludeDateId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteExcludeDate: builder.mutation<
      void,
      { excludeDateId: ExcludeDate['id'] }
    >({
      invalidatesTags: ['ExcludeDate'],
      query: ({ excludeDateId }) => ({
        url: `/date-exclusions/${excludeDateId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const excludeDatesApiEndpoints = excludeDatesApi.endpoints;
export const {
  useGetExcludeDatesQuery,
  useCreateExcludeDateMutation,
  useGetExcludeDateByIdQuery,
  useUpdateExcludeDateMutation,
  useDeleteExcludeDateMutation,
} = excludeDatesApi;
