import { Reservation } from '@/types/reservation';
import { api } from './api';

const reservationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReservations: builder.query<{ reservations: Reservation[] }, void>({
      providesTags: ['Reservation'],
      query: () => `/reservations`,
    }),
    createReservation: builder.mutation<
      { reservation: Reservation },
      Partial<Reservation>
    >({
      invalidatesTags: ['Reservation'],
      query: (body) => ({
        url: `/reservations`,
        method: 'POST',
        body,
      }),
    }),
    getReservationById: builder.query<
      { reservation: Reservation },
      { reservationId?: Reservation['id'] }
    >({
      providesTags: ['Reservation'],
      query: ({ reservationId }) => `/reservations/${reservationId}`,
    }),
    updateReservation: builder.mutation<
      { reservation: Reservation },
      { reservationId: Reservation['id']; body: Partial<Reservation> }
    >({
      invalidatesTags: ['Reservation'],
      query: ({ reservationId, body }) => ({
        url: `/reservations/${reservationId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteReservation: builder.mutation<
      void,
      { reservationId: Reservation['id'] }
    >({
      invalidatesTags: ['Reservation'],
      query: ({ reservationId }) => ({
        url: `/reservations/${reservationId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const reservationsApiEndpoints = reservationsApi.endpoints;
export const {
  useGetReservationsQuery,
  useCreateReservationMutation,
  useGetReservationByIdQuery,
  useUpdateReservationMutation,
  useDeleteReservationMutation,
} = reservationsApi;
