import { useDeleteReservationMutation } from '@/api/reservations';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Reservation } from '@/types/reservation';
import { Trash } from 'lucide-react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';

type Props = {
  reservationId?: Reservation['id'];
};

export const ReservationDeleteModal = ({
  reservationId,
}: Props): ReactElement => {
  const [deleteReservation] = useDeleteReservationMutation();
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      await deleteReservation({ reservationId: reservationId! });
      navigate(`/rezerwacje`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild className="w-full">
        <Button
          variant="ghost"
          className="px-2 w-full justify-start text-red-500 hover:text-red-500"
        >
          <Trash className="w-4 h-4 mr-1" />
          Usuń
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Czy na pewno chcesz usunąć tę rezerwację?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
            Twojej rezerwacji i usunięcie związanych z nią danych z naszych
            serwerów.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Anuluj</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>
            Kontynuuj
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
