import { Reservation } from '@/types/reservation';
import { User } from '@/types/user';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CredentialsStore } from './credentialsSlice';
import { ReservationsStore } from './reservationSlice';

type RootState = {
  credentials: CredentialsStore;
  reservations: ReservationsStore;
};

const selectUser = (state: RootState) => state.credentials.user;
const selectToken = (state: RootState) => state.credentials.accessToken;
const selectIsUserLoggedIn = createSelector(
  [selectToken],
  (accessToken) => !!accessToken,
);

export const useCurrentUser: () => User | undefined = () =>
  useSelector(selectUser)!;
export const useIsUserLoggedIn: () => boolean = () =>
  useSelector(selectIsUserLoggedIn);

export const useSelectedReservation: () => Reservation | undefined = () =>
  useSelector((state: RootState) => state.reservations.selectedReservation);
