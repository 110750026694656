import { useUpdateReservationMutation } from '@/api/reservations';
import { Reservation } from '@/types/reservation';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Calendar, Info, MoreHorizontal } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { AddEditReservation } from './AddEditReservation';
import { ReservationDeleteModal } from './ReservationDeleteModal';

export const columns: ColumnDef<Reservation>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'phoneNumber',
    header: 'Numer telefonu',
  },
  {
    accessorKey: 'from',
    header: 'Data',
    cell: ({ row }) => {
      const fromDate = new Date(row.original.from);
      const toDate = new Date(row.original.to);

      const isSameDate = fromDate.toDateString() === toDate.toDateString();

      const firsDay = format(fromDate, 'dd/MM/yyyy', { locale: pl });
      const lastDay = format(toDate, 'dd/MM/yyyy', { locale: pl });

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          {isSameDate ? (
            firsDay
          ) : (
            <p style={{ wordBreak: 'unset' }}>
              {firsDay} - {lastDay}
            </p>
          )}
        </span>
      );
    },
  },
  {
    accessorKey: 'confirmed',
    header: 'Potwierdzone',
    cell: ({ row }) => (row.original.confirmed ? 'Tak' : 'Nie'),
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [availablility, setAvailablility] = useState(
        !!row?.original?.confirmed,
      );
      const [updateReservation] = useUpdateReservationMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>Potwierdzone</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          defaultChecked={availablility}
                          onCheckedChange={() => {
                            const updatedAvailable = !availablility;
                            setAvailablility(updatedAvailable);
                            updateReservation({
                              reservationId: row.original.id,
                              body: { confirmed: updatedAvailable },
                            });
                          }}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Wyłączenie tej opcji spowoduje zmianę potwierdzenia</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <AddEditReservation type="update" reservation={row.original} />
              <ReservationDeleteModal reservationId={row.original.id} />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
