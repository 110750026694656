import { useDeleteExcludeDateMutation } from '@/api/date-exclusions';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { ExcludeDate } from '@/types/excludeDate';
import { Trash } from 'lucide-react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';

type Props = {
  excludeDateId?: ExcludeDate['id'];
};

export const ExcludeDatesDeleteModal = ({
  excludeDateId,
}: Props): ReactElement => {
  const [deleteExcludeDate] = useDeleteExcludeDateMutation();
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      await deleteExcludeDate({ excludeDateId: excludeDateId! });
      navigate(`/wykluczenia-dat`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild className="w-full">
        <Button
          variant="ghost"
          className="px-2 w-full justify-start text-red-500 hover:text-red-500"
        >
          <Trash className="w-4 h-4 mr-1" />
          Usuń
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Czy na pewno chcesz usunąć tę wykluczoną datę?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
            Twojej wykluczonej daty i usunięcie związanych z nią danych z
            naszych serwerów.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Anuluj</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>
            Kontynuuj
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
