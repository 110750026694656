import { useGetReservationsQuery } from '@/api/reservations';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { AddEditReservation } from './AddEditReservation';
import { DataTable } from './DataTable';
import { columns } from './columns';

export const Reservations = (): ReactElement => {
  const { data, isLoading } = useGetReservationsQuery();
  const reservations = data?.reservations?.length;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-10 mt-12 mb-8">
        <h1 className="text-2xl font-semibold leading-loose">Rezerwacje</h1>
        {!!reservations && <AddEditReservation light />}
      </div>

      {!!reservations ? (
        <DataTable columns={columns} data={data.reservations} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnej rezerwacji"
          element={<AddEditReservation />}
        />
      )}
    </div>
  );
};
