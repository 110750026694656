import { ExcludeDate } from '@/types/excludeDate';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Calendar, MoreHorizontal } from 'lucide-react';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { AddEditExcludeDate } from './AddEditExcludeDate';
import { ExcludeDatesDeleteModal } from './ExcludeDatesDeleteModal';

export const columns: ColumnDef<ExcludeDate>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'from',
    header: 'Data',
    cell: ({ row }) => {
      const fromDate = new Date(row.original.from);
      const toDate = new Date(row.original.to);

      const isSameDate = fromDate.toDateString() === toDate.toDateString();

      const firsDay = format(fromDate, 'dd/MM/yyyy', { locale: pl });
      const lastDay = format(toDate, 'dd/MM/yyyy', { locale: pl });

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          {isSameDate ? (
            firsDay
          ) : (
            <p style={{ wordBreak: 'unset' }}>
              {firsDay} - {lastDay}
            </p>
          )}
        </span>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <AddEditExcludeDate type="update" excludeDate={row.original} />
              <ExcludeDatesDeleteModal excludeDateId={row.original.id} />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
