import {
  useCreateReservationMutation,
  useUpdateReservationMutation,
} from '@/api/reservations';
import { Reservation } from '@/types/reservation';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { format } from 'date-fns';
import { CalendarIcon, File, Plus } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

const formSchema = z.object({
  email: z.string().email(),
  phoneNumber: z.string(),
  from: z.date(),
  to: z.date().refine(
    (endDate) => {
      const currentDate = new Date();
      return !endDate || endDate >= currentDate;
    },
    {
      message: 'Data zakończenia nie może być starsza niż obecna data.',
    },
  ),
});

type Props = {
  light?: boolean;
  type?: 'update';
  reservation?: Reservation;
};

export const AddEditReservation = ({
  light,
  type,
  reservation,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [createReservation] = useCreateReservationMutation();
  const [updateReservation] = useUpdateReservationMutation();

  const isUpdate = type === 'update';

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: reservation ? reservation.email : '',
      from: reservation ? new Date(reservation.from) : undefined,
      to: reservation ? new Date(reservation.to) : undefined,
    },
  });

  useEffect(() => {
    if (!!reservation) {
      form.reset(reservation as unknown as z.infer<typeof formSchema>);
    }
  }, [reservation, form.reset]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const isValid = await form.trigger();
      if (!isValid) {
        return;
      }

      console.log(values);

      if (type === 'update') {
        await updateReservation({
          reservationId: reservation?.id!,
          body: {
            ...values,
            from: values.from.toISOString(),
            to: values.to.toISOString(),
          },
        });
      } else {
        await createReservation({
          ...values,
          from: values.from.toISOString(),
          to: values.to.toISOString(),
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">
              Dodaj rezerwację
            </p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-132 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose mb-2">
            {type === 'update' ? 'Edytuj rezerwację' : 'Dodaj rezerwację'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Numer telefonu</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="from"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Rozpoczęcie</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={clsx(
                              'pl-3 text-left font-normal',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            {field?.value ? (
                              format(new Date(field.value), 'dd / MM / yyyy')
                            ) : (
                              <span>Wpisz datę</span>
                            )}
                            <CalendarIcon className="ml-auto mr-1 h-4 w-4 text-slate-700" />
                            <p className="text-slate-700 text-sm font-medium leading-normal">
                              Wybierz datę
                            </p>
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={new Date(field.value)}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="to"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Zakończenie</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={clsx(
                              'pl-3 text-left font-normal',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            {field.value ? (
                              format(new Date(field.value), 'dd / MM / yyyy')
                            ) : (
                              <span>Wpisz datę</span>
                            )}
                            <CalendarIcon className="ml-auto mr-1 h-4 w-4 text-slate-700" />
                            <p className="text-slate-700 text-sm font-medium leading-normal">
                              Wybierz datę
                            </p>
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={new Date(field.value)}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz rezerwację
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
